import React from "react";
import NavBar from "../components/navBar";
import "./home.css";
import Footer from "../components/Footer";
import { projects } from "../data/projects";
import { Link } from "react-router-dom";

const Projects = () => {
  return (
    <div className="bg-gray-100 text-gray-900">
      {/* Hero Section */}
      <div className="hero-section-about">
        <div className="slide"></div>
        <div className="hero-content h-screen relative">
          <NavBar />
          <header className="h-[50vh] flex justify-center text-white py-10 items-center relative">
            <div className="container mx-auto text-center flex flex-col items-center">
              <h1 className="text-4xl lg:text-7xl font-bold">
                Our Community Investment Projects
              </h1>
              <p className="mt-4 pb-4">– Musasa coffee, Blessed by kings –</p>
            </div>
          </header>
        </div>
      </div>

      <section
        className="container mx-auto px-4 lg:px-16 py-10 flex gap-8 flex-col"
        id="about"
      >
        <div className="text-lg leading-normal lg:px-32 px-4">
          <p className="mt-4 text-justify text-gray-700">
            Dukunde Kawa Musasa Cooperative is renowned for its farmer-centered
            business spirit, development initiatives, and effective poverty
            alleviation strategies. Through our cooperative, farmers have
            achieved significant improvements in their livelihoods, such as
            continuously capacity building, buying livestock, accessing
            long-term interest-free credit, paying for health insurance and
            pensions annually, and renovating their homes with tiled roofs and
            cemented floors, newest Musasa ECD for local children, further
            supporting community development.
          </p>
          <p className="mt-4 text-justify text-gray-700">
            Our cooperative employs innovative and dynamic strategies to
            alleviate poverty among coffee-farming families, operating various
            initiatives including:
          </p>
        </div>
        <div className="my-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {projects.map((project, index) => (
            <div
              key={index}
              className="bg-gray-100 w-72 mx-auto rounded shadow relative"
            >
              <img
                src={project.imgSrc}
                alt={project.alt}
                className="w-full object-cover rounded-lg"
              />
              <div className="absolute bottom-0 rounded-b-lg flex justify-between items-center gap-2 p-3 pt-64 gradient-bg hover:gradient-bg-hover w-full">
                <h3 className="text-xl font-bold text-white">
                  {project.title}
                </h3>
                <Link to={`/projects/${project.id}`}>
                  <span className="fas fa-arrow-right text-black bg-white p-4 text-center rounded-full hover:text-red-800 hover:bg-green-700"></span>
                </Link>
              </div>
            </div>
          ))}
        </div>
        <p className="my-4 mx-auto container px-4 lg:px-32 text-gray-700 py-4 flex gap-8 flex-col text-justify text-lg leading-normal">
          Through these initiatives, Dukunde Kawa Musasa enhances the quality
          and production of coffee while fostering sustainable development and
          economic growth in the region.
        </p>
      </section>
      <Footer />
    </div>
  );
};

export default Projects;
