import React, { useState } from "react";

const Gallery = ({ images, useFirstFour = false }) => {
  const [currentImage, setCurrentImage] = useState(null);

  const displayedImages = useFirstFour ? images.slice(0, 4) : images;

  const openModal = (index) => {
    setCurrentImage(index);
  };

  const closeModal = () => {
    setCurrentImage(null);
  };

  const previousImage = () => {
    setCurrentImage((prev) =>
      prev === 0 ? displayedImages.length - 1 : prev - 1
    );
  };

  const nextImage = () => {
    setCurrentImage((prev) =>
      prev === displayedImages.length - 1 ? 0 : prev + 1
    );
  };

  const getGridCols = (length) => {
    if (length <= 4) return "sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2";
    if (length <= 9) return "sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3";
    return "sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4";
  };

  const getHeightClass = (length) => {
    if (length <= 4) return "h-64";
    if (length <= 9) return "h-32";
    return "h-32";
  };

  const getMaxImages = (length) => {
    if (length <= 4) return 4;
    if (length <= 9) return 9;
    return 16;
  };

  const finalDisplayedImages = displayedImages.slice(
    0,
    getMaxImages(displayedImages.length)
  );

  return (
    <div className="container mx-auto lg:px-2 py-4 bg-white" id="gallery">
      <div
        className={`grid grid-cols-2 ${getGridCols(
          finalDisplayedImages.length
        )} gap-1`}
      >
        {finalDisplayedImages.map((image, index) => (
          <div
            key={index}
            className="relative overflow-hidden bg-gray-200 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out"
          >
            <img
              src={image.src}
              alt={image.alt}
              className={`w-full ${getHeightClass(
                finalDisplayedImages.length
              )} object-cover cursor-pointer gallery-image`}
              onClick={() => openModal(index)}
            />
          </div>
        ))}
      </div>

      {currentImage !== null && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
          <div className="relative max-w-screen-lg w-full p-4">
            <button
              className="absolute top-2 right-2 text-white text-2xl modal-button"
              onClick={closeModal}
            >
              &times;
            </button>
            <button
              className="absolute top-1/2 left-2 text-white text-2xl modal-button transform -translate-y-1/2"
              onClick={previousImage}
            >
              &lt;
            </button>
            <img
              src={finalDisplayedImages[currentImage].src}
              alt={finalDisplayedImages[currentImage].alt}
              className="w-auto max-h-screen mx-auto rounded-lg shadow-lg modal-image"
            />
            <button
              className="absolute top-1/2 right-2 text-white text-2xl modal-button transform -translate-y-1/2"
              onClick={nextImage}
            >
              &gt;
            </button>
            <div className="text-white text-center mt-4">
              {finalDisplayedImages[currentImage].alt}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
