import React from "react";
import NavBar from "./navBar";
import "../pages/home.css";
import { coffeeData } from "../data/coffee";
import { useLocation } from "react-router";
import Gallery from "./aboutUs/Gallery";

const RoastedCoffee = () => {
  const { pathname } = useLocation();

  return (
    <div>
      <div className="hero-section-roasted ">
        <div className="slide"></div>
        <div className="hero-content h-screen relative">
          <NavBar />
          <header className=" h-[50vh] flex justify-center text-white py-10 items-center relative">
            <div className="container mx-auto text-center flex flex-col items-center ">
              <h1 className="text-4xl lg:text-7xl font-bold">
                Roasted Coffee.
              </h1>
              <p className="mt-4 pb-4">– Musasa coffee, Blessed by kings –</p>
            </div>
          </header>
        </div>
      </div>
      <section
        className="container mx-auto px-4 lg:px-16 py-10 flex gap-8 flex-col"
        id="about"
      >
        <h2 className="text-5xl font-bold text-green-800 text-center">
          Roasted Coffee
        </h2>
        <div className="flex lg:flex-row flex-col-reverse gap-8 justify-center lg:px-16 px-4">
        
          <div className="lg:flex-1 pt-10">
            <Gallery
              images={coffeeData
                .find((c) => c.path === pathname)
                .images.map((img) => ({
                  src: img,
                  alt: "Roasted coffee",
                }))}
            />
          </div>
          <div className="flex-1 text-lg leading-normal ">
            <div className="flex pt-8 justify-center"></div>
            <p className="mt-4 text-justify text-gray-700">
              Musasa coffee stands out as a specialty brew meticulously produced
              by the small-scale farmers of the Dukunde Kawa Musasa cooperative,
              nestled in the undulating hills of Northwest Rwanda, close to the
              habitat of majestic mountain gorillas. This coffee thrives in the
              nutrient-rich volcanic soil, benefiting from biannual rainfall at
              elevations ranging from 1800 to 2200 meters. Each bean is
              handpicked at its peak ripeness, ensuring the selection of the
              juiciest, most flavorful coffee cherries. These cherries undergo a
              meticulous wet-milling process, overseen with dedication by family
              farmers who pour their passion into every step.
            </p>
            <p className="mt-4 text-justify text-gray-700">
              {" "}
              The unique growing conditions of Musasa Speciality Coffee, coupled
              with the careful processing and expert roasting techniques, yield
              a brew with a captivating complexity and vibrant character.
              Crafted from 100% Arabica red bourbon beans and roasted to a
              medium perfection, it exudes notes of grape jam, vanilla, and
              clementine, culminating in a delightful finish accentuated by
              sweet lemon undertones. Its round, juicy body and subtle raspberry
              acidity further enhance the sensory experience
            </p>
            <p className="mt-4 text-justify text-gray-700">
              {" "}
              Musasa coffee is available in both organic and conventional
              packages, sealed in specially designed bags to preserve its
              freshness for up to 12 months. With every purchase, you not only
              savor the perfection of Musasa coffee but also support the
              livelihoods of the dedicated farmers who nurture it from seed to
              cup. We are farmers, offering you the chance to indulge in the
              essence of our labor of love.
            </p>
          </div>
        </div>

        <div className="my-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
          {coffeeData
            .filter((c) => c.path !== pathname)
            .map((coffee, index) => (
              <div
                key={index}
                className="bg-gray-100 w-72 mx-auto rounded shadow relative"
              >
                <img
                  src={coffee.imgSrc}
                  alt={coffee.alt}
                  className="w-full object-cover rounded-lg"
                />
                <div className="absolute bottom-0 rounded-b-lg flex justify-between items-center gap-4 p-3 pt-64 gradient-bg hover:gradient-bg-hover w-full">
                  <h3 className="text-xl font-bold text-white">
                    {coffee.title}
                  </h3>
                  <a href={coffee.path}>
                    <span className="fas fa-arrow-right text-black bg-white p-4 text-center rounded-full hover:text-red-800 hover:bg-green-700"></span>
                  </a>
                </div>
              </div>
            ))}
        </div>
      </section>
    </div>
  );
};

export default RoastedCoffee;
