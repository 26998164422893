import React, { useState } from "react";
import axios from "axios";
import NavBar from "../components/navBar";
import Footer from "../components/Footer";
import "./home.css";

const ContactUsPage = () => {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    message: "",
  });

  const [alertMessage, setAlertMessage] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      fullname: `${formData.firstname} ${formData.lastname}`,
      email: formData.email,
      mailTo: process.env.REACT_APP_DUKUNDEKAWA_MAIL,
      subject: "Contact Form Submission",
      phone: formData.phone,
      message: formData.message,
    };

    try {
      const response = await axios.post(
        process.env.REACT_APP_MAILSERVICE_API,
        data
      );
      if (response.status === 200) {
        setAlertMessage("Email sent successfully!");
        setFormData({
          firstname: "",
          lastname: "",
          email: "",
          phone: "",
          message: "",
        });
      } else {
        setAlertMessage("Failed to send email.");
      }
    } catch (error) {
      console.error("There was an error sending the email!", error);
      setAlertMessage("There was an error sending the email.");
    }
  };

  return (
    <div className="bg-gray-100 text-gray-900">
      {/* Hero Section */}
      <div className="hero-section-about">
        <div className="slide"></div>
        <div className="hero-content h-screen relative">
          <NavBar />
          <header className="h-[50vh] flex justify-center text-white py-10 items-center relative">
            <div className="container mx-auto text-center flex flex-col items-center">
              <h1 className="text-4xl lg:text-7xl font-bold">Contact Us</h1>
              <p className="mt-4 pb-4">– Musasa coffee, Blessed by kings –</p>
            </div>
          </header>
        </div>
      </div>

      {/* Contact Section */}
      <section className="py-10 bg-white" id="contact">
        <div className="container mx-auto px-4 lg:px-16 py-10">
          <h2 className="text-3xl lg:text-5xl font-bold text-center mb-10 text-green-700">
            Get in Touch
          </h2>
          <div className="flex flex-col-reverse md:grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="flex flex-col space-y-6">
              {/* <h3 className="text-2xl font-bold text-gray-800">
                Any questions?
              </h3>
              <p className="text-xl font-semibold text-gray-600 mb-16">
                We would be happy to help you!
                
              </p> */}
              <div className="flex flex-col lg:flex-row gap-2 text-xs">
                <p className="bg-gray-100 rounded-lg text-gray-600 outline outline-1 py-4 px-4 outline-gray-300 max-w-96 mt-2 flex-1">
                  <i className="fas fa-phone mr-2"></i>+250782142193
                </p>
                <p className="bg-gray-900 text-white rounded-lg outline outline-1 py-4 px-4 outline-gray-300 max-w-96 mt-2 flex-1">
                  <i className="fas fa-envelope mr-2"></i>
                  info@dukundekawa.rw
                </p>
                <p className="bg-gray-100 rounded-lg text-gray-600 outline outline-1 py-4 px-4 outline-gray-300 max-w-96 mt-2 flex-1">
                  <i className="fas fa-map-marker-alt mr-2"></i> Rwanda,
                  Gakenke, Ruli.
                </p>
              </div>
              <div className="rounded-lg overflow-hidden shadow-lg">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3987.8255631617144!2d29.842647209915512!3d-1.8109494981648135!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19dc950ca418b86b%3A0xa232ef873fd42695!2sDUKUNDE%20KAWA%20MUSASA%20HQ!5e0!3m2!1sen!2srw!4v1718184173881!5m2!1sen!2srw"
                  width="600"
                  height="350"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
              {/* <p className="rounded-lg text-md text-gray-600 outline outline-1 py-4 px-6 outline-gray-300 max-w-96 mt-4">
                <i className="fas fa-phone mr-3"></i>+250 786 286 650 | +250 782
                142 193
              </p>
              <p className="bg-gray-900 text-md text-white ml-2 rounded-lg outline outline-1 py-4 px-6 outline-gray-300 max-w-96 mt-4">
                <i className="fas fa-envelope mr-3"></i>
                info@dukundekawa.rw
              </p>
              <p className="rounded-lg text-md text-gray-600 outline outline-1 py-4 px-6 outline-gray-300 max-w-96 mt-4">
                <i className="fas fa-map-marker-alt mr-3"></i> Rwanda, Northern
                Province, Gakenke, Ruli.
              </p> */}
            </div>
            <div>
              {alertMessage && (
                <div
                  className={`alert ${
                    alertMessage.includes("successfully")
                      ? "alert-success"
                      : "alert-error"
                  }`}
                >
                  {alertMessage}
                </div>
              )}
              <form onSubmit={handleSubmit} className="space-y-4">
                <div className="flex gap-4">
                  <div className="flex-1">
                    <label className="block text-gray-700">First Name</label>
                    <input
                      type="text"
                      name="firstname"
                      value={formData.firstname}
                      onChange={handleChange}
                      className="w-full p-2 border border-gray-300 rounded"
                      placeholder="First name"
                      required
                    />
                  </div>
                  <div className="flex-1">
                    <label className="block text-gray-700">Last Name</label>
                    <input
                      type="text"
                      name="lastname"
                      value={formData.lastname}
                      onChange={handleChange}
                      className="w-full p-2 border border-gray-300 rounded"
                      placeholder="Last name"
                      required
                    />
                  </div>
                </div>
                <div>
                  <label className="block text-gray-700">Email</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded"
                    placeholder="yourmail@email.com"
                    required
                  />
                </div>
                <div>
                  <label className="block text-gray-700">Phone</label>
                  <input
                    type="text"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded"
                    placeholder="+250788998899"
                  />
                </div>
                <div>
                  <label className="block text-gray-700">Message</label>
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded"
                    rows="4"
                    placeholder="Type your message here..."
                    required
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="w-full text-white mt-10 bg-red-900 hover:bg-green-700 px-14 py-3 rounded-lg transition-colors duration-300 ease-in-out"
                >
                  Send Message{" "}
                  <i className="fas fa-paper-plane text-white text-xs ml-2"></i>
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default ContactUsPage;
