import React from "react";
import NavBar from "./navBar";
import "../pages/home.css";
import Gallery from "./aboutUs/Gallery";
import { coffeeData } from "../data/coffee";
import { useLocation } from "react-router";

const NaturalCoffee = () => {
  const { pathname } = useLocation();
  return (
    <div>
      <div className="hero-section-natural ">
        <div className="slide"></div>
        <div className="hero-content h-screen relative">
          <NavBar />
          <header className=" h-[50vh] flex justify-center text-white py-10 items-center relative">
            <div className="container mx-auto text-center flex flex-col items-center ">
              <h1 className="text-4xl lg:text-7xl font-bold">
                Natural Coffee.
              </h1>
              <p className="mt-4 pb-4">– Musasa coffee, Blessed by kings –</p>
            </div>
          </header>
        </div>
      </div>
      <section
        className="container mx-auto px-4 lg:px-16 py-10 flex gap-8 flex-col"
        id="about"
      >
        <h2 className="text-5xl font-bold text-green-800 text-center">
          Natural Coffee
        </h2>
        <div className="flex lg:flex-row flex-col-reverse gap-8 justify-center lg:px-16 px-4">
          <div className="lg:flex-1 pt-10">
            <Gallery
              images={coffeeData
                .find((c) => c.path === pathname)
                .images.map((img) => ({
                  src: img,
                  alt: "Natural coffee",
                }))}
            />
          </div>
          <div className="flex-1 text-lg leading-normal">
            <div className="flex pt-8 justify-center"></div>
            <p className="mt-4 text-justify text-gray-700">
              It is a coffee bean that is dried with its pulp (dried within its
              natural shape). Musasa Natural is second to none because it is
              processed from a selected ripest-heavy-juicy coffee cherries among
              the day deliveries. We normally select region or hills and quite
              number of farmers who would contribute to natural coffee process
              and we involve exhausting selection steps during the processes.
            </p>
            <p className="mt-4 text-justify text-gray-700">
              {" "}
              At site collection, farmers have to sink their coffee to remove
              the floaters, and at mill reception, we use cherry sorter machine
              to sort again by density. All the lighter beans are sort out and
              will contribute to lower grade- fully washed coffee process that
              will be sold at local market.
            </p>
            <p className="mt-4 text-justify text-gray-700">
              {" "}
              After cherry sorting machine, the selected beans are taken
              directly to sun drying for about 50-60 days depending on weather
              conditions. During the drying process, women keep turning the
              beans and sorting out the defects until homogeneous drying is
              done.
            </p>
            <p className="mt-4 text-justify text-gray-700">
              {" "}
              The further processes of milling, grading, color sorting and hand
              sorting are quite similar to other coffee process.
            </p>
            <p className="mt-4 text-justify text-gray-700">
              {" "}
              his coffee opens with the unmistakable flavors of strawberries,
              blueberries, and chocolate. The pleasant acidity associated with
              strawberry is balanced by a chocolate sweetness. As the taste
              lingers, the strawberry fades into banana, which mixes with the
              heavy chocolate body into something truly amazing.
            </p>
          </div>
        </div>

        <div className="my-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
          {coffeeData
            .filter((c) => c.path !== pathname)
            .map((coffee, index) => (
              <div
                key={index}
                className="bg-gray-100 w-72 mx-auto rounded shadow relative"
              >
                <img
                  src={coffee.imgSrc}
                  alt={coffee.alt}
                  className="w-full object-cover rounded-lg"
                />
                <div className="absolute bottom-0 rounded-b-lg flex justify-between items-center gap-4 p-3 pt-64 gradient-bg hover:gradient-bg-hover w-full">
                  <h3 className="text-xl font-bold text-white">
                    {coffee.title}
                  </h3>
                  <a href={coffee.path}>
                    <span className="fas fa-arrow-right text-black bg-white p-4 text-center rounded-full hover:text-red-800 hover:bg-green-700"></span>
                  </a>
                </div>
              </div>
            ))}
        </div>
      </section>
    </div>
  );
};

export default NaturalCoffee;
