import React from "react";

const Footer = () => {
  return (
    <div>
      <footer className="bg-gray-100 py-10">
        <div className="container mx-auto px-8 lg:px-20 xl:px-24 grid grid-cols-1 md:grid-cols-3 gap-8 text-center md:text-left">
          {/* About Us Section */}
          <div>
            <h3 className="text-3xl font-bold text-green-900 mb-4">About us</h3>
            <p className="text-gray-800 mt-2" id="about-us">
              <strong className="text-green-800">Address:</strong> Northern
              Province, Gakenke District, Ruli.
            </p>
            <h3 className="text-3xl font-bold text-green-900 my-8">
              Chat with Us
            </h3>
            <div
              className="mt-2 flex justify-center md:justify-start"
              id="contact-us"
            >
              <a
                target="_blank"
                href="https://api.whatsapp.com/send?phone=250782142193"
                className="flex items-center bg-green-500 hover:bg-green-600 text-white rounded-full px-4 py-2 transition-colors duration-300"
              >
                <i className="fab fa-whatsapp text-3xl mr-2"></i>
                <div>
                  <p>
                    Coffee Sales{" "}
                    <span className="text-xs bg-green-400 text-white px-1 rounded">
                      Online
                    </span>
                  </p>
                  <p>Need to taste our coffee? </p>
                  <p>Chat with us +250782142193</p>
                </div>
              </a>
            </div>
          </div>

          {/* Explore Section */}
          <div className="px-8">
            <h3 className="text-3xl font-bold text-green-900">Explore</h3>
            <ul className="text-gray-700 mt-2 grid grid-cols-2 space-y-2 font-semibold">
              <li className="hover:text-red-800 flex items-center gap-2">
                <i className="fas fa-chevron-right text-xs"></i>
                <a href="/">Home</a>
              </li>
              <li className="hover:text-red-800 flex items-center gap-2">
                <i className="fas fa-chevron-right text-xs"></i>
                <a href="/our-cws">Our cws</a>
              </li>
              <li className="hover:text-red-800 flex items-center gap-2">
                <i className="fas fa-chevron-right text-xs"></i>
                <a href="/about-us">About Us</a>
              </li>
              <li className="hover:text-red-800 flex items-center gap-2">
                <i className="fas fa-chevron-right text-xs"></i>
                <a href="/projects">Projects</a>
              </li>
              <li className="hover:text-red-800 flex items-center gap-2">
                <i className="fas fa-chevron-right text-xs"></i>
                <a href="/contact-us">Contact Us</a>
              </li>
            </ul>

            {/* Social Media Section */}
            <div className="mt-10">
              <h3 className="text-3xl font-bold text-green-900">
                We are social
              </h3>
              <div className="mt-4 flex justify-center lg:justify-start space-x-4">
                <a
                  href="https://web.facebook.com/Dukundekawa"
                  target="_blank"
                  className="relative group transform transition-transform hover:-translate-y-1"
                >
                  <img
                    src="/assets/thin_facebook.png"
                    alt="facebook"
                    className="w-12 max-w-auto"
                  />
                  <span className="absolute bottom-0 right-0 text-xs bg-green-800 text-white px-1 rounded group-hover:opacity-100 opacity-0 transition-opacity duration-300">
                    200
                  </span>
                </a>
                <a
                  href="https://twitter.com/dukundekawa"
                  target="_blank"
                  className="relative group transform transition-transform hover:-translate-y-1"
                >
                  <img
                    src="/assets/thin_twitter.png"
                    alt="twitter"
                    className="w-12 max-w-auto"
                  />
                  <span className="absolute bottom-0 right-0 text-xs bg-green-800 text-white px-1 rounded group-hover:opacity-100 opacity-0 transition-opacity duration-300">
                    150
                  </span>
                </a>
                <a
                  href="https://www.youtube.com/@dukundekawamusasa3780"
                  target="_blank"
                  className="transform transition-transform hover:-translate-y-1"
                >
                  <img
                    src="/assets/thin_youtube.png"
                    alt="youtube"
                    className="w-12 max-w-auto"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/dukundekawa/"
                  target="_blank"
                  className="transform transition-transform hover:-translate-y-1"
                >
                  <img
                    src="/assets/thin_linkedin.png"
                    alt="linked"
                    className="w-12 max-w-auto"
                  />
                </a>
                <a
                  href="https://www.instagram.com/dukundekawa_rw/"
                  target="_blank"
                  className="transform transition-transform hover:-translate-y-1"
                >
                  <img
                    src="/assets/thin_instagram.png"
                    alt="instagram"
                    className="w-12 max-w-auto"
                  />
                </a>
                <a
                  href="https://api.whatsapp.com/send?text=//dukundekawa.rw"
                  target="_blank"
                  className="transform transition-transform hover:-translate-y-1"
                >
                  <img
                    src="/assets/thin_whatsapp.png"
                    alt="whatsapp"
                    className="w-12 max-w-auto"
                  />
                </a>
              </div>
            </div>
          </div>

          {/* We are Section */}
          <div>
            <h3 className="text-2xl font-bold text-green-900">We are</h3>
            <a href="/">
              <img
                src="/assets/logo.jpg"
                alt="Dukunde Kawa"
                className="mx-auto md:mx-0 my-4 w-32"
              />
            </a>

            <h3 className="text-2xl font-bold text-green-900">
              Publications and Announcements
            </h3>
            <a
              href="https://dukundekawa.rw/wp-content/uploads/2018/02/DUKUNDEKAWA-COOPERATIVE-QUALITY-POLICY-STATEMENT.pdf"
              target="_blank"
              className="relative h-10 overflow-hidden text-gray-700 mt-2 flex flex-col items-center"
            >
              <div className="scroll-container">
                <p>DUKUNDEKAWA COOPERATIVE QUALITY POLICY STATEMENT</p>
                <p>DUKUNDEKAWA COOPERATIVE QUALITY POLICY STATEMENT</p>
              </div>
            </a>
          </div>
        </div>
      </footer>
      {/* Additional Footer Section */}
      <div className="relative flex bg-green-900 text-white px-4 lg:px-12 py-3">
        <div className="mx-auto flex lg:flex-row flex-col gap-x-12 text-sm">
          <div>
            <i className="fas fa-phone text-white text mr-2"></i>
            +250 786 286 650 | +250 782 142 193
          </div>
          <div>
            <i className="fas fa-map-marker-alt text-white text-xs mr-2"></i>{" "}
            Rwanda, Northern Province, Gakenke, Ruli.
          </div>
          <div>
            <i className="fas fa-clock text-white mr-2"></i> 24 Hours a day, 7
            Days a week
          </div>
        </div>
      </div>
      <div className="bg-yellow-200 py-4 relative">
        <div className="container mx-auto px-4 lg:px-12 font-semibold flex justify-center items-center">
          <p className="text-black text-sm text-center">
            DUKUNDEKAWA COOPERATIVE © All Rights Reserved - 2021.
          </p>
          <a
            href="#top"
            className="text-green-800 p-2 text-xl rounded-full bg-green-100 hover:bg-red-700 hover:text-white transition-all hover:-translate-y-1 transform duration-300 absolute right-4"
          >
            <i className="fas fa-chevron-up"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
