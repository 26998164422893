import React from "react";
import NavBar from "./navBar";
import "../pages/home.css";
import { coffeeData } from "../data/coffee";
import { useLocation } from "react-router";
import Gallery from "./aboutUs/Gallery";

const HoneyCoffee = () => {
  const { pathname } = useLocation();

  return (
    <div>
      <div className="hero-section-honey ">
        <div className="slide"></div>
        <div className="hero-content h-screen relative">
          <NavBar />
          <header className=" h-[50vh] flex justify-center text-white py-10 items-center relative">
            <div className="container mx-auto text-center flex flex-col items-center ">
              <h1 className="text-4xl lg:text-7xl font-bold">Honey Coffee.</h1>
              <p className="mt-4 pb-4">– Musasa coffee, Blessed by kings –</p>
            </div>
          </header>
        </div>
      </div>
      <section
        className="container mx-auto px-4 lg:px-16 py-10 flex gap-8 flex-col"
        id="about"
      >
        <div className="flex-1 text-lg leading-normal lg:px-32 px-4">
          <h2 className="text-5xl font-bold text-green-800 text-center">
            Honey Coffee
          </h2>
          <div className="flex pt-8 justify-center"></div>
          <div className="flex lg:flex-row flex-col-reverse gap-8 justify-center">
            <div className="lg:flex-1 pt-4">
              <Gallery
                images={coffeeData
                  .find((c) => c.path === pathname)
                  .images.map((img) => ({
                    src: img,
                    alt: "Honey coffee",
                  }))}
              />
            </div>
            <div className="flex-1 text-lg ">
              <p className="mt-4 text-justify text-gray-700">
                Honey coffee is another type of coffee from a dry processing
                technique that consists of pulping the coffee cherries and
                immediately dry the humid parchment with the slippery
                layer/mucilage on it.
              </p>
              <p className="mt-4 text-justify text-gray-700">
                {" "}
                The factors that contribute to outstanding performance of Musasa
                Honey coffee are classified in 3 dimensions:
              </p>
              <p className="mt-4 text-justify text-gray-700">
                {" "}
                <b>1. Favorable Microclimate condition:</b> In our region, the
                coffee is grown is high hills up to 2200 m of altitude, in rich
                volcanic soil with adequate rainfall in full year that result in
                heavy, juicy, and red cheery with lots of mucilage content,
                therefore, more sugar content.
              </p>
              <p className="mt-4 text-justify text-gray-700">
                {" "}
                <b>
                  2. Cherry selection process on quality, density, and timing:
                </b>{" "}
                farmers are trained to harvest the coffee for Honey process (we
                look for juicy beans with a lot of mucilage and processed in
                middle of peak season). They normally harvest by Manual
                selective harvesting on well ripened cherries, and the coffee
                has to be sorted again by hand to remove bad quality cherries.
                The coffee undergone same procedure of sorting as previously
                explained on natural process (At site collection, farmers have
                to sink their coffee to remove the floaters, and at mill
                reception, we use cherry sorter machine to sort again by
                density. All the lighter beans sorted out, will contribute to
                lower grade- fully washed coffee process that will be sold at
                local market).
              </p>
            </div>
          </div>
          <p className="mt-4 text-justify text-gray-700">
            {" "}
            <b>3. Caramelization process:</b> Pulping is done mechanically to
            remove pulp without using water to get the humid parchment. Then,
            the wet parchment is transported on raised bed and massed in heap of
            3 cm width maximum for 3 days. During this period, the wet parchment
            is laid to rest without turning up, and we keep them wrapped by a
            mesh and Nylex. After 2 days, coffee will start feeling sticky,
            darker and the task left will be to separate all the beans with
            clean hands, just to prevent any wild fermentation from happening.
            This process is called caramelization.
          </p>
          <p className="mt-4 text-justify text-gray-700">
            {" "}
            After this process, coffee should be moved as many times as possible
            in the first week and sort out the defects while turning up the
            coffee until homogeneous drying process. The appropriate humidity
            level is 11% for the drying process. Drying process is around 25-30
            days depending on weather condition. The further processes of
            milling, grading, color sorting and hand sorting are quite similar
            to other coffee process.
          </p>
        </div>
        <div className="my-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
          {coffeeData
            .filter((c) => c.path !== pathname)
            .map((coffee, index) => (
              <div
                key={index}
                className="bg-gray-100 w-72 mx-auto rounded shadow relative"
              >
                <img
                  src={coffee.imgSrc}
                  alt={coffee.alt}
                  className="w-full object-cover rounded-lg"
                />
                <div className="absolute bottom-0 rounded-b-lg flex justify-between items-center gap-4 p-3 pt-64 gradient-bg hover:gradient-bg-hover w-full">
                  <h3 className="text-xl font-bold text-white">
                    {coffee.title}
                  </h3>
                  <a href={coffee.path}>
                    <span className="fas fa-arrow-right text-black bg-white p-4 text-center rounded-full hover:text-red-800 hover:bg-green-700"></span>
                  </a>
                </div>
              </div>
            ))}
        </div>
      </section>
    </div>
  );
};

export default HoneyCoffee;
