import React from "react";
import Footer from "../components/Footer";
import HoneyCoffee from "../components/HoneyCoffee";

const HoneyCoffeePage = () => {
  return (
    <div>
      <HoneyCoffee />
      <Footer />
    </div>
  );
};

export default HoneyCoffeePage;
