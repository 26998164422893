import React from "react";
import Footer from "../components/Footer";
import NaturalCoffee from "../components/NaturalCoffee";

const NaturalCoffeePage = () => {
  return (
    <div>
      <NaturalCoffee />
      <Footer />
    </div>
  );
};

export default NaturalCoffeePage;
