import React from "react";
import NavBar from "./navBar";
import "../pages/home.css";
import { coffeeData } from "../data/coffee";
import { useLocation } from "react-router";
import Gallery from "./aboutUs/Gallery";

const FullyWashedCoffee = () => {
  const { pathname } = useLocation();
  return (
    <div>
      <div className="hero-section-full">
        <div className="slide"></div>
        <div className="hero-content h-screen relative">
          <NavBar />
          <header className=" h-[50vh] flex justify-center text-white py-10 items-center relative">
            <div className="container mx-auto text-center flex flex-col items-center ">
              <h1 className="text-4xl lg:text-7xl font-bold">
                Fully washed coffee.
              </h1>
              <p className="mt-4 pb-4">– Musasa coffee, Blessed by kings –</p>
            </div>
          </header>
        </div>
      </div>
      <section
        className="container mx-auto px-4 lg:px-16 py-10 flex gap-8 flex-col"
        id="about"
      >
        <div className="flex-1 text-lg leading-normal lg:px-32 px-4">
          <h2 className="text-5xl font-bold text-green-800 text-center">
            Fully Washed Coffee Story
          </h2>
          <div className="flex pt-8 justify-center"></div>
          <div className="flex lg:flex-row flex-col-reverse gap-8 justify-center ">
            <div className="lg:flex-1 pt-4">
              <Gallery
                images={coffeeData
                  .find((c) => c.path === pathname)
                  .images.map((img) => ({
                    src: img,
                    alt: "Full Washed coffee",
                  }))}
              />
            </div>
            <div className="flex-1 text-lg ">
              <p className="mt-4 text-justify text-gray-700">
                Musasa coffee is merely produced by small-scare farmers’
                cooperative called Dukunde Kawa in the rolling hills of
                Northwest of Rwanda, just near the mountain gorilla habitat.
                Grown in rich volcanic soil with two annual periods of rainfall,
                to an altitude of 1800 - 2200 m, the area possesses a unique
                micro-climate that creates a wonderfully complex, distinct
                flavors that results in unforgettable coffee cup.
              </p>
              <p className="mt-4 text-justify text-gray-700">
                {" "}
                Most of the small-scale producers with whom Musasa Dukunde Kawa
                works own less than a quarter of a hectare of land, where they
                cultivate an average of only 450 - 600 coffee trees each as well
                as other subsistence food crops such as maize and beans, but the
                level of care they put in growing coffee trees and take over the
                processing are impressive.
              </p>
            </div>
          </div>
          <p className="mt-4 text-justify text-gray-700">
            {" "}
            Cherries are hand-picked only when fully ripe and farmers deliver
            their harvests to site collection centers within 8 hours. On
            arrival, farmers themselves do soaking before weighing to ensure
            that only ripen and good quality coffee beans are taken. From site
            to coffee washing station, coffee is transported by truck, bicycles
            or by head. Upon delivery as cherry, the coffee receives a paper
            ‘ticket’ that follows the lot through all its processing. This
            ticket has the date of harvest and the grade (A1, A2, etc.) of the
            coffee. For instance, if a coffee lot is called ‘Lot 1-06/04 -A1’,
            this means it was the first lot processed on 6th April and the grade
            is A1. This simple but effective practice is a crucial tool in
            controlling quality and ensuring the traceability of lots. After
            weighing and name tagging lot, the cherries are re-sorted by weight
            (and any floaters are removed) by a Pinhalense machine that the
            washing station staff affectionately have named the ‘Umupolisi’
            (police person) prior to pulping where light cherries are removed
            and processed separately as lower grade coffee. The retained heavy
            beans go through a series of coffee de-pulping and grading to ensure
            the highest possible coffee quality. To ensure the coffee mucilage
            are reduced, the coffee goes through mucilage remover machine to
            reduce the time of fermentation. This practice saves beyond 6 hours
            that would be taken during fermentation. Dry fermentation last
            usually 12 hours and then the wet parchment is washed by high
            pressured water and grading is done throughout long channels to
            separate coffee by density. During this grading stage, coffee beans
            pass through a series of canals that allow lighter, less delicious
            of coffee beans to pass through, while retaining the heavy, sweeter
            beans. The retained washed beans (the heaviest – or A1 – usually
            being the best) are moved from channels to pre-drying tables, where
            they are carefully sorted under shade for around 4 hours.
          </p>
          <p className="mt-4 text-justify text-gray-700">
            {" "}
            Next, the beans are taken on raised drying tables for around 14 days
            in warm days or 21 days in rainy days. The coffee is also sorted
            again for defects, any damaged beans and it is turned regularly by
            women (seasonal workers) and the latter are tasked to protect coffee
            from rain or the midday sun by using covers. Almost every day, the
            coffee is tested for relative humidity by quality controller for the
            period of drying and when the coffee reaches 11% relative humidity,
            the coffee is taken to store prior to final dry-milling processing.
          </p>
          <p className="mt-4 text-justify text-gray-700">
            {" "}
            The store is well aerated, and the coffee is laid on pallets, far
            from wall to avoid any contact with humidity. Humidity is checked
            regularly and coffee lots are cupped by Dukunde Kawa Cupper along
            with the Q-graders of our exporting partner, RWASHOSCCO. At milling
            factory, the coffee is carefully milled by different machinery,
            including pre-cleaning machinery, Destoner that remove stones and
            Catador in cleaning of coffee after the hulling of coffee. this
            machine is used in removal of light, broken, bits, and other
            impurities. The coffee pass on size grader where screen 15+ are
            collected and then coffee is sorted on vibrating gravity table that
            sorts coffee by density. Later, it passes through color sorter
            machine that removes bad quality coffee and any defects based on
            color with accuracy of 95%. The coffee is then sorted by hands by
            experienced seasonal women workers where they remove broken beans,
            damaged by pests and some greenish beans. Prior to shipment, the
            coffee lots are mixed in lager coffee bulking machine to ensure the
            homogeneity of the lot in container.
          </p>
          <p className="mt-4 text-justify text-gray-700">
            {" "}
            We are certified with Fairtrade organic, Rain Forest Alliance, and
            C.A.F.E. Practices certifications. Musasa Coffee won Cup of
            Excellence 2010, 2011, 2012, 2013, 2014, 2015, 2018 in row in front
            of independent panels at different places.
          </p>
          <p className="mt-4 text-justify text-gray-700">
            {" "}
            This coffee opens with the unmistakable flavors of strawberries,
            blueberries, and chocolate. With a lovely balance of sweet and
            bright, this coffee brings juicy clementine’s backed up by silky
            butterscotch and milk chocolate. Finishing with sweet lemon, that
            citrus hit keeps going with a lingering lime.
          </p>
        </div>

        <div className="my-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
          {coffeeData
            .filter((c) => c.path !== pathname)
            .map((coffee, index) => (
              <div
                key={index}
                className="bg-gray-100 w-72 mx-auto rounded shadow relative"
              >
                <img
                  src={coffee.imgSrc}
                  alt={coffee.alt}
                  className="w-full object-cover rounded-lg"
                />
                <div className="absolute bottom-0 rounded-b-lg flex justify-between items-center gap-4 p-3 pt-64 gradient-bg hover:gradient-bg-hover w-full">
                  <h3 className="text-xl font-bold text-white">
                    {coffee.title}
                  </h3>
                  <a href={coffee.path}>
                    <span className="fas fa-arrow-right text-black bg-white p-4 text-center rounded-full hover:text-red-800 hover:bg-green-700"></span>
                  </a>
                </div>
              </div>
            ))}
        </div>
      </section>
    </div>
  );
};

export default FullyWashedCoffee;
