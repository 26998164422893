import React from "react";
import { Link, useParams } from "react-router-dom";
import NavBar from "../components/navBar";
import Footer from "../components/Footer";
import { projects } from "../data/projects";
import "./home.css";
import Gallery from "../components/aboutUs/Gallery";

const SingleProject = () => {
  const { id } = useParams();
  const project = projects.find((project) => project.id === parseInt(id));

  if (!project) {
    return <p>Project not found</p>;
  }

  return (
    <div className="bg-gray-100 text-gray-900">
      {/* Hero Section */}
      <div className="hero-section-about ">
        <div className="slide"></div>
        <div className="hero-content h-screen relative">
          <NavBar />
          <header className=" h-[50vh] flex justify-center text-white py-10 items-center relative">
            <div className="container mx-auto text-center flex flex-col items-center ">
              <h1 className="text-4xl lg:text-7xl font-bold">
                {project.title}
              </h1>
              <p className="mt-4 pb-4">– Musasa coffee, Blessed by kings –</p>
            </div>
          </header>
        </div>
      </div>

      <section
        className="container mx-auto px-4 lg:px-16 py-10 bg-white"
        id="projects"
      >
        <div className="container mx-auto px-4 lg:px-16 py-10 flex lg:gap-8 gap-4 lg:flex-row flex-col lg:odd:flex-row-reverse">
          <div className="flex-1">
            <h2 className="lg:text-4xl text-2xl font-bold text-green-800 text-center">
              {project.title}
            </h2>
            <p className="mt-4 text-justify text-gray-700">
              {project.description}<br />
            {project.id === 4 && (
              <a
                href="../assets/Musasa_agrotourism_booklet.pdf"
                download={true}
                className="button block w-fit bg-red-800 hover:bg-green-700 text-white px-10 py-2 rounded-full transition-colors duration-300 ease-in-out"
              >
                Download the project booklet
              </a>
            )}
            </p>
          </div>
          <div className="lg:flex-1">
            <Gallery
              images={project.images.map((img) => ({
                src: img,
                alt: project.title,
              }))}
            />
          </div>
        </div>

        <div className="my-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
          {projects
            .filter((p) => p.id !== parseInt(id))
            .map((project, index) => (
              <div
                key={index}
                className="bg-gray-100 w-72 mx-auto rounded shadow relative"
              >
                <img
                  src={project.imgSrc}
                  alt={project.alt}
                  className="w-full object-cover rounded-lg"
                />
                <div className="absolute bottom-0 rounded-b-lg flex justify-between items-center gap-4 p-3 pt-64 gradient-bg hover:gradient-bg-hover w-full">
                  <h3 className="text-xl font-bold text-white">
                    {project.title}
                  </h3>
                  <a href={`/projects/${project.id}`}>
                    <span className="fas fa-arrow-right text-black bg-white p-4 text-center rounded-full hover:text-red-800 hover:bg-green-700"></span>
                  </a>
                </div>
              </div>
            ))}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default SingleProject;
