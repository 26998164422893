import React from "react";
import NavBar from "../components/navBar";
import "./home.css";
import Footer from "../components/Footer";
import Gallery from "../components/aboutUs/Gallery";

const coffeeStations = [
  {
    title: "Ruli Station",
    description:
      "The Ruli Coffee washing station dubbed MUSASA literally means “place to make a bed” is located in Gakenke district/ Ruli sector/ Jango cell in Gatagara village. It started operations in 2004 serving 300 cooperative pioneer members and now it is the biggest station among 4 coffee washing stations owned by the cooperative. The station sits on top of a large hill overlooking an overlapping network of valleys and other equally impressive hills just 70 Km from the Kigali capital of Rwanda in an area with enormous specialty coffee potential where love of coffee to all villagers is exceptional and climate condition is favorable. The cherries produced by over 1759 farmers mostly small-scale growers are certified Fairtrade, Rain Forest Alliance and C.A.F.E. Practices certifications. The altitude of the farms is about 1800m – 2100m above sea level and the Coffee washing station sits at an altitude of 2002 m asl. The variety grown is Arabica bourbon. The harvesting months are March to October with production about 4 containers of exportable green coffee.",
    images: [
      "/assets/stations/Ruli/DEN_3137.JPG",
      "/assets/stations/Ruli/DEN_3224.JPG",
      "/assets/stations/Ruli/DEN_3170.JPG",
      "/assets/stations/Ruli/DEN_3242.JPG",
    ],
  },
  {
    title: "Mbilima Station",
    description:
      "The MBILIMA coffee washing station is the second station of Dukunde Kawa Cooperative established in 2005 with profits earned from their first washing station to serve the members of the area after realizing that farmers struggle and spent many hours to deliver the harvest to Musasa Coffee washing station and quality is destroyed throughout the journey. The coffee washing stations is installed on top of a hill with a unique panoramic view of numerous hills stretching like interlocking fingers across the landscape and cooler fresh air. The Mbilima Coffee washing station is located in Gakenke district/ Coko sector/ Mbilima cell in Akanduga village and It sits at around 2020 meters above sea level making it one of Rwanda’s highest washing stations. The altitude of the farms is about 1800m – 2100m above sea level. The harvesting months are March to October with production about 3 containers of exportable green coffee. Farms owned by 890 farmers mostly small-scale growers in the area organized around the Mbilima coffee washing station are dedicated for organic production since 2015 mainly thanks to mineral-rich soil and a lush environment that is well-suited to specialty coffee. The variety grown is Arabica bourbon. The cherries produced in 72 hectares of land are 100% organic Rainforest Alliance certified UTZ certified and Fair Trade certified.",
    images: [
      "/assets/stations/Mbilima/DEN_2899.JPG",
      "/assets/stations/Mbilima/DEN_2721.JPG",
      "/assets/stations/Mbilima/DEN_2573.JPG",
      "/assets/stations/Mbilima/DEN_2852.JPG",
    ],
  },
  {
    title: "Nkara Station",
    description:
      "The Nkara Coffee washing station is located in Gakenke district/ Ruli sector/ Busoro cell in Gitaba village. It was established by Dukunde Kawa cooperative in 2007 by income generated from two first coffee washing stations to lessen the burden of coffee supply for farmers in the area. The area is situated between high rugged hills of Muhondo Ruli and Coko sectors. Nkara washing station began serving local farmers in 2007-8 and today buys and processes cherries from between 15-20% of the cooperative’s membership. The numbers and paperwork involved are substantial! With the help of the Wet Mill manager, 4 permanent employees and around 53 seasonal workers, Nkara has grown to process 2 containers of exportable coffee lots. The variety grown is Arabica bourbon. The cherries produced in 27.63 hectares of land are certified with the Rain Forest Alliance, UTZ and C.A.F.E. Practices certifications. Farmers located in this region are 226 farmers mostly small-scale growers that grow coffee and other food crops like beans and cassava. The altitude of the farms is about 1800m – 2100m above sea level and the CWS is at an altitude of about 1800 m. The harvesting months are March to October.",
    images: [
      "/assets/stations/Nkara/DEN_2931.JPG",
      "/assets/stations/Nkara/DEN_3015.JPG",
      "/assets/stations/Nkara/DEN_3106.JPG",
      "/assets/stations/Nkara/DEN_3068.JPG",
    ],
  },
  {
    title: "Musambira Station",
    description:
      "Musambira Coffee Washing Station was acquired in 2015 as part of our expansion strategy to meet the growing demand in coffee markets and to strengthen our investment in the local community beyond our initial operations. Situated in Kamonyi District, Southern Province, it collaborates with approximately 1,600 farmers from the region. This station offers a unique opportunity for diversification in quality and flavors, enhancing our coffee profiles for customers worldwide. It also helps alleviate pressure on raw materials during periods of heightened competition in the condensed northern region. Benefiting from proximity to tarmac roads, Musambira facilitates efficient transportation of cherries, thereby improving the quality of coffee from farm to processing. Despite its modest beginnings, this growing station has significantly enhanced its infrastructure to ensure improved quality standards. ",
    images: [
      "/assets/stations/Musambira/IMG_6874.JPG",
      "/assets/stations/Musambira/IMG_6875.JPG",
      "/assets/stations/Musambira/IMG_6876.JPG",
      "/assets/stations/Musambira/Musasa_MCM_12_Photo by Denyse K. Uwera @denysewera.jpg",
    ],
  },
];

const CWSStations = () => {
  return (
    <div className="bg-gray-100 text-gray-900">
      {/* Hero Section */}
      <div className="hero-section-about ">
        <div className="slide"></div>
        <div className="hero-content h-screen relative">
          <NavBar />
          <header className=" h-[50vh] flex justify-center text-white py-10 items-center relative">
            <div className="container mx-auto text-center flex flex-col items-center ">
              <h1 className="text-4xl lg:text-7xl font-bold">
                Our Coffee Washing Stations
              </h1>
              <p className="mt-4 pb-4">– Musasa coffee, Blessed by kings –</p>
            </div>
          </header>
        </div>
      </div>

      <section
        className="container mx-auto px-4 lg:px-16 py-10 bg-white"
        id="stations"
      >
        {coffeeStations.map((station, index) => (
          <div
            key={index}
            className="container mx-auto px-4 lg:px-16 py-10 flex lg:gap-8 gap-4 lg:flex-row flex-col lg:odd:flex-row-reverse"
          >
            <div className="flex-1">
              <h2 className="lg:text-4xl text-2xl font-bold text-green-800 text-center">
                {station.title}
              </h2>
              <p className="mt-4 text-justify text-gray-700">
                {station.description}
              </p>
            </div>
            <div className="lg:flex-1">
              <Gallery
                images={station.images.map((img) => ({
                  src: img,
                  alt: station.title,
                }))}
              />
            </div>
          </div>
        ))}
      </section>
      <Footer />
    </div>
  );
};

export default CWSStations;
