import React from "react";
import NavBar from "../navBar";
import "../../pages/home.css";
import MeetOurTeam from "./MeetOurTeam";

const AboutSection = () => {
  return (
    <div>
      <div className="hero-section-about ">
        <div className="slide"></div>
        <div className="hero-content h-screen relative">
          <NavBar />
          <header className=" h-[50vh] flex justify-center text-white py-10 items-center relative">
            <div className="container mx-auto text-center flex flex-col items-center ">
              <h1 className="text-4xl lg:text-7xl font-bold">About Us.</h1>
              <p className="mt-4 pb-4">– Musasa coffee, Blessed by kings –</p>
            </div>
          </header>
        </div>
      </div>
      <section
        className="container mx-auto px-4 lg:px-16 py-10 flex gap-8 lg:flex-row flex-col"
        id="about"
      >
        <div className="flex-1">
          <div className="profile">
            <h2 className="text-2xl lg:text-4xl font-bold text-green-800 text-center mt-10">
              Cooperative Profile
            </h2>
            <p className="mt-4 text-justify text-gray-700">
              Dukunde Kawa Musasa Cooperative, meaning "Let's Love Coffee," is a
              cooperative of coffee growers established in 2000. Headquartered
              in the Ruli Sector of Gakenke District, Northern Province of
              Rwanda, we cultivate, and process high-quality coffee known as
              “Musasa Coffee,” named after our region. Fully owned by 1,193
              members and works with over 4,500 coffee producers in the area,
              Dukunde Kawa Musasa owns a roastery, a dry mill, and four wet
              mills, with an annual capacity to produce 288 metric tons of green
              beans. Since 2004, we have been Fair Trade Certified and certified
              ISO 1900-2015 standards since 2022, ensuring a high standard of
              living for Rwandan farmers by promoting an economically and
              environmentally sustainable coffee industry. The cooperative
              invests half of its profits into various community projects,
              furthering our commitment to improving the quality of life for our
              members. By prioritizing sustainability and quality, Dukunde Kawa
              Musasa Cooperative continues to elevate the standards of coffee
              production while fostering community growth and development.<br />
            <a
              target="_blank"
              download={true}
              href="../assets/Musasa_cooperative_profile.pdf"
              className="button block w-fit bg-red-800 hover:bg-green-700 text-white px-10 py-2 rounded-full transition-colors duration-300 ease-in-out"
            >
              Download our profile
            </a>
            </p>
          </div>

          <div className="grid lg:grid-cols-3 flex-wrap gap-8 items-center justify-center my-8">
            <div className="flex justify-center flex-col rounded-2xl px-4 py-4 lg:px-8 lg:py-6 border bg-gray-100">
              <h2 className="text-2xl lg:text-4xl font-bold text-green-800 text-center">
                Vision
              </h2>
              <p className="mt-4 text-justify text-gray-700">
                To be the leading producer of the Speciality Arabica Coffee as
                green and roasted coffee products under Musasa Coffee brand.
              </p>
            </div>
            <div className="flex justify-center flex-col rounded-2xl px-4 py-4 lg:px-8 lg:py-6 border bg-gray-100">
              <h2 className="text-2xl lg:text-4xl font-bold text-green-800 text-center">
                Mission Statement
              </h2>
              <p className="mt-4 text-justify text-gray-700">
                We believe that coffee is beyond the great taste but also can
                change lives. Dukunde Kawa Musasa Cooperative strives to improve
                livelihoods and financial independence of its members through
                production of high-valued coffee products and social programs
                with a high level of biodiversity and natural resources
                conservation.
              </p>
            </div>
            <div className="flex justify-center flex-col rounded-2xl px-4 py-4 lg:px-8 lg:py-6 border bg-gray-100">
              <h2 className="text-2xl lg:text-4xl font-bold text-green-800 text-center">
                Objectives of the Cooperative
              </h2>
              <p className="mt-4 text-justify text-gray-700">
                Our cooperative ambitions are to produce speciality coffee with
                a great taste to amaze coffee lovers with quality service to our
                customers and improve the welfare of coffee farmers in our
                region. We invest in next generation of coffee through youth
                engaging initiatives and support gender equality for our women
                farmer members to fully participate in the cooperative’s
                activities and its leadership.
              </p>
            </div>
          </div>
        </div>
      </section>
      <MeetOurTeam />
    </div>
  );
};

export default AboutSection;
