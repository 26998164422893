import React, { useState } from "react";
import NavBar from "../components/navBar";
import Footer from "../components/Footer";
import "./gallery.css";

const images = [
  { src: "/assets/gallery/DEN_2529.JPG", alt: "Fresh coffee beans" },
  { src: "/assets/gallery/DEN_2530.JPG", alt: "Beans drying in the sun" },
  { src: "/assets/gallery/DEN_2561.JPG", alt: "Beans drying around container" },
  { src: "/assets/gallery/DEN_2584.JPG", alt: "Worker carrying beans" },
  { src: "/assets/gallery/DEN_2590.JPG", alt: "Sorting coffee beans" },
  { src: "/assets/gallery/DEN_2942.JPG", alt: "Drying racks" },
  { src: "/assets/gallery/DEN_2962.JPG", alt: "Beans drying on racks" },
  { src: "/assets/gallery/DEN_3070.JPG", alt: "Processing facility and truck" },
  { src: "/assets/gallery/DEN_3085.JPG", alt: "Beans on drying rack" },
  { src: "/assets/gallery/DEN_3227.JPG", alt: "Processing plant entrance" },
  { src: "/assets/gallery/DEN_3242.JPG", alt: "Coffee processing" },
  { src: "/assets/gallery/DEN_3249.JPG", alt: "Coffee beans" },
  { src: "/assets/gallery/DSC_2552.jpg", alt: "Coffee cherries" },
  { src: "/assets/gallery/IMG_2016.JPG", alt: "Coffee sorting" },
  { src: "/assets/gallery/IMG_2025.JPG", alt: "Coffee beans" },
  {
    src: "/assets/gallery/Women Harvesting Coffee.JPG",
    alt: "Women harvesting coffee",
  },
];

const Gallery = () => {
  const [currentImage, setCurrentImage] = useState(null);

  const openModal = (index) => {
    setCurrentImage(index);
  };

  const closeModal = () => {
    setCurrentImage(null);
  };

  const previousImage = () => {
    setCurrentImage((prev) => (prev === 0 ? images.length - 1 : prev - 1));
  };

  const nextImage = () => {
    setCurrentImage((prev) => (prev === images.length - 1 ? 0 : prev + 1));
  };

  return (
    <div className="bg-gray-100 text-gray-900">
      {/* Hero Section */}
      <div className="hero-section-about">
        <div className="slide"></div>
        <div className="hero-content h-screen relative">
          <NavBar />
          <header className="h-[50vh] flex justify-center text-white py-10 items-center relative">
            <div className="container mx-auto text-center flex flex-col items-center">
              <h1 className="text-4xl lg:text-7xl font-bold">Our Gallery</h1>
              <p className="mt-4 pb-4">– Musasa coffee, Blessed by kings –</p>
            </div>
          </header>
        </div>
      </div>

      <section
        className="container mx-auto px-4 lg:px-16 py-10 bg-white"
        id="gallery"
      >
        <div className="grid grid-cols-2 sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-4 gap-4">
          {images.map((image, index) => (
            <div
              key={index}
              className="relative overflow-hidden bg-gray-200 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out"
            >
              <img
                src={image.src}
                alt={image.alt}
                className="w-full h-64 object-cover cursor-pointer gallery-image"
                onClick={() => openModal(index)}
              />
              <div className="absolute bottom-0 bg-black bg-opacity-50 w-full text-white text-center py-2">
                {image.alt}
              </div>
            </div>
          ))}
        </div>

        {currentImage !== null && (
          <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
            <div className="relative max-w-screen-lg w-full p-4">
              <button
                className="absolute top-2 right-2 text-white text-2xl modal-button"
                onClick={closeModal}
              >
                &times;
              </button>
              <button
                className="absolute top-1/2 left-2 text-white text-2xl modal-button transform -translate-y-1/2"
                onClick={previousImage}
              >
                &lt;
              </button>
              <img
                src={images[currentImage].src}
                alt={images[currentImage].alt}
                className="w-auto max-h-screen mx-auto rounded-lg shadow-lg modal-image"
              />
              <button
                className="absolute top-1/2 right-2 text-white text-2xl modal-button transform -translate-y-1/2"
                onClick={nextImage}
              >
                &gt;
              </button>
              <div className="text-white text-center mt-4">
                {images[currentImage].alt}
              </div>
            </div>
          </div>
        )}
      </section>

      <Footer />
    </div>
  );
};

export default Gallery;
