import { useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const navItems = [
  { name: "Home", path: "/" },
  { name: "Our cws", path: "/our-cws" },
  { name: "About Us", path: "/about-us" },
  { name: "Projects", path: "/projects" },
  { name: "Contact us", path: "/contact-us" },
];

const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <nav className="stiscky top-0 z-50 w-full ">
      <div className="flex items-center justify-between w-full text-xs font-semibold font-BelloText py-5 px-10">
        <img
          src="/assets/Dukunde-kawa-Musasa-Final-Logo-04.png"
          className="w-auto h-14"
          alt="Logo"
        />
        <div className="items-center justify-evenly hidden gap-6 min-[1207px]:flex pr-10">
          <ul className="flex gap-8">
            {navItems.map((item) => (
              <li key={item.path}>
                <Link
                  to={item.path}
                  className={`text-[17px] text-white cursor-pointer hover:text-[#353E44] hover:underline hover:underline-offset-8 block mb-1 ${
                    location.pathname === item.path ? "!text-red-600" : ""
                  }`}
                >
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
          <div className="flex items-center gap-2">
            <Link to={"/signup"}>
              <img src="/assets/tosignup.svg" alt="" className="w-5" />
            </Link>
            <img src="/assets/shopfull.svg" alt="" className="w-5" />
          </div>
        </div>
        <div className="flex items-center min-[1207px]:hidden">
          <button onClick={toggleMenu}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="white"
              className="w-8 h-8"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </button>
        </div>
      </div>
      <div
        ref={menuRef}
        className={`fixed top-0 left-0 w-full h-full bg-[#1F2127] text-white flex flex-col items-center justify-center z-50 transition-transform transform ${
          isMenuOpen ? "translate-x-0" : "translate-x-full"
        } min-[1207px]:hidden`}
      >
        <button onClick={toggleMenu} className="absolute top-4 right-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="white"
            className="w-8 h-8 text-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
        </button>
        <ul className="flex flex-col gap-8 text-2xl">
          {navItems.map((item) => (
            <li key={item.path}>
              <Link
                to={item.path}
                onClick={closeMenu}
                className={`hover:text-gray-400 ${
                  location.pathname === item.path ? "text-gray-400" : ""
                }`}
              >
                {item.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
