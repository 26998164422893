export const coffeeData = [
  {
    id: 1,
    imgSrc: "/assets/Fully-washed-760x1000.jpg",
    title: "Fully Washed Coffee",
    path: "/fullywashed-coffee",
    images: [
      "/assets/products/Fully_Washed/Coffee is grown on higher altitude which affect wonderful flavors in cup.jpg",
      "/assets/products/Fully_Washed/coffee is turned regularly by women (seasonal workers) to ensure homogenous drying process.jpg",
      "/assets/products/Fully_Washed/Drying is done on on raised drying tables  for around 14-21 days.jpg",
      "/assets/products/Fully_Washed/IMG_2007.JPG",
      "/assets/products/Fully_Washed/IMG_2016.JPG",
      "/assets/products/Fully_Washed/IMG_2025.JPG",
      "/assets/products/Fully_Washed/IMG_2031.JPG",
      "/assets/products/Fully_Washed/Much Care and affections put in coffee farming results in most elegant coffees in world.JPG",
      "/assets/products/Fully_Washed/Warm smile behing the passion and care women put into the coffee farming (1).JPG",
      "/assets/products/Fully_Washed/Women Harvesting Coffee.JPG",
      "/assets/products/Fully_Washed/Women sorting Coffee.JPG",
      "/assets/products/Fully_Washed/women workers pouring coffee parchement into huller machine at Dry mill.jpg",
    ],
  },
  {
    id: 2,
    imgSrc: "/assets/Honey-Coffee-760x1000.jpg",
    title: "Honey Coffee",
    path: "/honey-coffee",
    images: [
      "/assets/products/Honey/_DSC3493.jpg",
      "/assets/products/Honey/DSC_2495.jpg",
      "/assets/products/Honey/DSC_4606.jpg",
      "/assets/products/Honey/IGIHE PICTURES 1_119.JPG",
    ],
  },
  {
    id: 3,
    imgSrc: "/assets/roasted_coffee.jpg",
    title: "Roasted Coffee",
    path: "/roasted-coffee",
    images: [
      "/assets/products/Roasted/DSC_2817.jpg",
      "/assets/products/Roasted/DSC_2829.jpg",
      "/assets/products/Roasted/Musasa Mockups.png",
      "/assets/products/Roasted/Musasa Packaging MockUp Final.jpg",
    ],
  },
  {
    id: 4,
    imgSrc: "/assets/Natural-coffee-1-760x1000.jpg",
    title: "Natural Coffee",
    path: "/natural-coffee",
    images: [
      "/assets/products/Natural/DSC_2538.jpg",
      "/assets/products/Natural/DSC_2552.jpg",
      "/assets/products/Natural/Musasa_MCM_5_Photo by Denyse K. Uwera @denysewera.JPG",
      "/assets/products/Natural/Musasa_MCM_17_Photo by Denyse K. Uwera @denysewera.jpg",
    ],
  },
];
