import React from "react";
import NavBar from "../components/navBar";
import "./home.css";
import Footer from "../components/Footer";

const Home = () => {
  const coffeeData = [
    {
      imgSrc: "/assets/Fully-washed-760x1000.jpg",
      title: "Fully Washed Coffee",
      path: "/fullywashed-coffee"
    },
    {
      imgSrc: "/assets/Natural-coffee-1-760x1000.jpg",
      title: "Natural Coffee",
      path: "/natural-coffee"
    },
    {
      imgSrc: "/assets/Honey-Coffee-760x1000.jpg",
      title: "Honey Coffee",
      path: "/honey-coffee"
    },
    {
      imgSrc: "/assets/roasted_coffee.jpg",
      title: "Roasted Coffee",
      path: "/roasted-coffee"
    },
  ];

  return (
    <div className="bg-gray-100 text-gray-900">
      {/* Hero Section */}
      <div className="hero-section ">
        <div className="slide"></div>
        <div className="slide"></div>
        <div className="slide"></div>
        <div className="hero-content h-screen relative">
          <NavBar />
          <header className="h-screen flex justify-center text-white py-10 items-center relative">
            <div className="container mx-auto text-center flex flex-col items-center ">
              <h1 className="text-4xl lg:text-7xl font-bold">
                We are coffee farmers.
              </h1>
              <p className="mt-4 py-8 w-1/2 text-center">
                Our commitment is to amaze coffee lovers with one of the best
                Rwandan specialty green and roasted – Musasa coffee.
              </p>
              <p className="mt-4 pb-4">– Musasa coffee, Blessed by kings –</p>
              <a
                href="#coffee"
                className="mt-10 bg-red-900 hover:bg-green-700 text-white px-14 py-3 rounded-full transition-colors duration-300 ease-in-out"
              >
                Explore our coffee
              </a>
            </div>
          </header>
        </div>
      </div>

      {/* Introduction Section */}
      <section
        className="container mx-auto px-2 lg:px-16 py-10 flex gap-8 lg:flex-row flex-col"
        id="about"
      >
        <div className="flex-1">
          <h2 className="mt-20 text-2xl lg:text-5xl font-bold">
            Dukundekawa | translates-
          </h2>
          <h2 className="text-2xl lg:text-5xl font-semibold text-green-800">
            Let's love coffee.
          </h2>
          <p className="mt-4 text-justify">
            We are a cooperative of coffee growers, founded in 2000,&nbsp;
            Dukundekawa Cooperative also known as Musasa cultivates its high
            quality coffee in the soil located between 1800 – 2200 m of altitude
            just near the mountain gorilla habitat in central Rwanda. By
            producing high-end coffee for the international market and employing
            a majority female workforce, the cooperative ensures maximum
            benefits to local families. Currently, Dukundekawa – Musasa accounts
            1193 coffee farmers which make it capable of producing up to 12
            containers of green coffee per year. Having been Fair Trade
            Certified since 2004, Dukunde Kawa secures a high standard of living
            for Rwandan farmers by ensuring access to an economically and
            environmentally sustainable coffee industry. Eighty percent (80%) of
            Dukundekawa’s producers are women. The cooperative owns 4 wet mills
            all covering more than 392ha of arable land and one dry mill.
          </p>
          <a
            href="/about-us"
            className="mt-10 block w-fit mx-auto bg-red-900 hover:bg-green-700 text-white px-14 py-3 rounded-full transition-colors duration-300 ease-in-out"
          >
            Read more about us
          </a>
        </div>
        <div className="flex-1 pt-28">
          <img src="/assets/Lets-love-coffee.jpg" alt="Let's love coffee" />
          <h5 className="text-red-900 text-xl text-center font-semibold mt-10">
            We produce great quality Rwandan coffee under our own brand -Musasa
            coffee from farm through processing to the final roasted speciality
            coffee.
          </h5>
        </div>
      </section>

      {/* Coffee Section */}
      <section className="py-10 bg-yellow-100" id="coffee">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold text-center">
            Musasa <span className="text-red-800">Coffee.</span>
          </h2>
          <p className="lg:w-2/5 px-8 m-auto my-8 text-justify">
            Musasa speciality coffee is grown on the rich volcanic loam soil of
            the high hills of Rwanda at more than 2,000m above the sea level.
            The coffee is harvested and brought to our washing stations to be
            processed with great care by family farmers who have the passion of
            great coffee farming practices for as far as memory goes. The 100%
            arabica red bourbon coffee is available in Fully washed, Natural,
            Honey and currently roasted types.
          </p>
          <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {coffeeData.map((coffee, index) => (
              <div
                key={index}
                className="bg-gray-100 w-72 mx-auto rounded shadow relative"
              >
                <img
                  src={coffee.imgSrc}
                  alt={coffee.alt}
                  className="w-full object-cover rounded-lg"
                />
                <div className="absolute bottom-0 rounded-b-lg flex justify-between items-center gap-4 p-3 pt-64 gradient-bg hover:gradient-bg-hover w-full">
                  <h3 className="text-xl font-bold text-white">
                    {coffee.title}
                  </h3>
                  <a href={coffee.path}><span className="fas fa-arrow-right text-black bg-white p-4 text-center rounded-full hover:text-red-800 hover:bg-green-700"></span></a> 
                </div>
              </div>
            ))}
          </div>

          {/* <a
            href="#coffee"
            className="mt-10 block w-fit mb-20 mx-auto bg-red-900 hover:bg-green-700 text-white px-14 py-4 rounded-full transition-colors duration-300 ease-in-out"
          >
            Learn more
          </a> */}
        </div>
      </section>

      {/* How we produce our coffee Section */}
      <section className="pt-10 bg-white" id="how-we-produce">
        <p className="text-5xl text-center pt-8 pb-5 font-bold">
          How we produce our coffee
        </p>
        <div className="mx-auto grid grid-cols-1 md:grid-cols-3">
          <div className="flex flex-col items-center bg-green-700 p-12 justify-center gap-14">
            <iframe
              className="w-full h-64 rounded-lg shadow-lg"
              src="https://www.youtube.com/embed/0GkYTY_5thk"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <h3 className="text-2xl font-bold text-black mb-4 text-justify">
              <span className="text-yellow-200 ">
                Producing quality coffee is a complex and unique process{" "}
              </span>
              and it goes through several stages.
            </h3>
          </div>

          {/* Characteristics Section */}
          <div className="flex flex-col bg-yellow-200 p-10 pt-12 justify-center ">
            <h3 className="text-2xl font-bold mb-8">
              Characteristics{" "}
              <span className="text-green-800">of our coffee.</span>
            </h3>
            <ul className="list-none">
              <li className="flex items-center mb-2">
                <span className="fas fa-check text-green-700 mr-4"></span>
                <span>Crop: current</span>
              </li>
              <li className="flex items-center mb-2">
                <span className="fas fa-check text-green-700 mr-4"></span>
                <span>Variety: Arabica, Bourbon</span>
              </li>
              <li className="flex items-center mb-2">
                <span className="fas fa-check text-green-700 mr-4"></span>
                <span>Grade: Specialty</span>
              </li>
              <li className="flex items-center mb-2">
                <span className="fas fa-check text-green-700 mr-4"></span>
                <span>Altitude: 1800 – 2000 meters above the sea level</span>
              </li>
              <li className="flex items-center mb-2">
                <span className="fas fa-check text-green-700 mr-4"></span>
                <span>Aroma: Honey, Lemon, Floral, Apple</span>
              </li>
              <li className="flex items-center mb-2">
                <span className="fas fa-check text-green-700 mr-4"></span>
                <span>Screen size: 15+</span>
              </li>
              <li className="flex items-center mb-2">
                <span className="fas fa-check text-green-700 mr-4"></span>
                <span>Processing: Fully washed, Natural, Honey</span>
              </li>
              <li className="flex items-center mb-2">
                <span className="fas fa-check text-green-700 mr-4"></span>
                <span>Soil type: Volcanic loam</span>
              </li>
            </ul>
          </div>

          <div className="flex flex-col items-center bg-green-700 p-12 justify-center ">
            <iframe
              className="w-full h-64 rounded-lg shadow-lg"
              src="https://www.youtube.com/embed/xAjwpvNiz78"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <h3 className="text-2xl font-bold text-yellow-200 mb-4 ">
              Fairtrade Premium Impact Stories
              <span className="text-black"> – Dukunde Kawa Musasa.</span>
            </h3>
            <p className="text-white font-thin leading-6">
              <strong>
                We are well known for our farmers-centered business spirit,
                development, and ways of mitigating poverty. We are the first
                cooperative-certified Fairtrade in Rwanda in 2005. We invested
                Fairtrade premiums in various development projects that impacted
                coffee producers and impactful stories are well captured in this
                short video.
              </strong>
            </p>
          </div>
        </div>
      </section>

      {/* Certifications Section */}
      <section className="certifications">
        <div className="flex flex-col lg:flex-row justify-center gap-4 px-16 text-center bg-black py-16">
          <div className="w-72 text-justify">
            <h2 className="text-white text-2xl mb-2">Our Certifications</h2>
            <p className="text-gray-300">
              Our smallholder farms and processing factory comply with the
              standards of various certification bodies each year.
            </p>
          </div>

          <div className="flex justify-center gap-5 flex-wrap">
            <div className="image-container relative">
              <img
                src="/assets/Musasa-Certificates-02.png"
                alt="Fairtrade"
                className="scroll-image"
              />
              <img
                src="/assets/Musasa-Certificates-02.png"
                alt="Fairtrade Alt"
                className="scroll-image second"
              />
            </div>
            <div className="image-container">
              <img
                src="/assets/Musasa-Certificates-01.png"
                alt="Control Union"
                className="scroll-image"
              />
              <img
                src="/assets/Musasa-Certificates-01.png"
                alt="Control Union Alt"
                className="scroll-image second"
              />
            </div>
            <div className="image-container">
              <img
                src="/assets/Musasa-Certificates-03.png"
                alt="Rainforest Alliance"
                className="scroll-image"
              />
              <img
                src="/assets/Musasa-Certificates-03.png"
                alt="Rainforest Alliance Alt"
                className="scroll-image second"
              />
            </div>
            <div className="image-container">
              <img
                src="/assets/Musasa-Certificates-07.png"
                alt="RSB Standardization Mark"
                className="scroll-image"
              />
              <img
                src="/assets/Musasa-Certificates-07.png"
                alt="RSB Standardization Mark Alt"
                className="scroll-image second"
              />
            </div>
            <div className="image-container">
              <img
                src="/assets/Musasa-Certificates-05.png"
                alt="C.A.F.E. Practices"
                className="scroll-image"
              />
              <img
                src="/assets/Musasa-Certificates-05.png"
                alt="C.A.F.E. Practices Alt"
                className="scroll-image second"
              />
            </div>
            <div className="image-container">
              <img
                src="/assets/Musasa-Certificates-06.jpg"
                alt="RSB Standardization Mark"
                className="scroll-image"
              />
              <img
                src="/assets/Musasa-Certificates-06.jpg"
                alt="RSB Standardization Mark Alt"
                className="scroll-image second"
              />
            </div>
            <div className="image-container">
              <img
                src="/assets/Musasa-Certificates-04.jpg"
                alt="UTZ Certified"
                className="scroll-image"
              />
              <img
                src="/assets/Musasa-Certificates-04.jpg"
                alt="UTZ Certified Alt"
                className="scroll-image second"
              />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Home;
