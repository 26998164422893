import React from "react";
import Footer from "../components/Footer";
import FullyWashedCoffee from "../components/FullyWashedCoffee";

const FullyWashedCoffeePage = () => {
  return (
    <div>
      <FullyWashedCoffee />
      <Footer />
    </div>
  );
};

export default FullyWashedCoffeePage;
