export const projects = [
  {
    id: 1,
    title: "Women coffee project",
    description:
      "Women empowerment is a top priority for Dukunde Kawa Musasa Cooperative. In 2012, we established the Rambagira Kawa Women Group, a self-governed collective under our cooperative's leadership, primarily composed of widows who head their families. This group aims to foster social cohesion and create a lending scheme by growing and selling women-produced coffee, processed separately to fetch premiums that are reinvested into women's projects. The Rambagira Kawa Women Group has achieved remarkable success, now selling over two containers of coffee annually under their own brand, “Angelique Finest.” They manage their financial transactions independently, have bought small livestock, and run their own lending scheme and office, which also serves as a selling point. Additionally, the group engages in off-season income-generating activities such as producing reusable sanitary pads, beautiful baskets, and garments. Women constitute 80% of the workforce in administrative and coffee processing roles. Thanks to the efforts and successes of this group, Dukunde Kawa Musasa Cooperative has been recognized as the African Overall winner in Fairtrade Africa Gender equality champions of the year in Africa (8th March 2022) among all African producers certified fair trade in coffee, tea, and cacao",
    imgSrc:
      "/assets/projects/Women/Much Care and affections put in coffee farming results in most elegant coffees in world.JPG",
    images: [
      "/assets/projects/Women/Much Care and affections put in coffee farming results in most elegant coffees in world.JPG",
      "/assets/projects/Women/Musasa_MCM_19_Photo by Denyse K. Uwera @denysewera.JPG",
      "/assets/projects/Women/Women after harvesting, they bring harvest within 8 hours.JPG",
      "/assets/projects/Women/Women Harvesting Coffee.JPG"
    ],
  },
  {
    id: 2,
    title: "Dairy Project",
    description:
      "Since 2008, in partnership with various organizations, we have used coffee sales premiums to purchase dairy cows for our members. This initiative supports income diversification, provides manure for organic coffee production, and ensures access to a healthy diet. As milk production increased in the region, the Musasa Dairy was established in 2015, which processes milk into fresh milk, fermented milk, and yogurt. The cooperative’s diversification into dairy products, alongside coffee production, ensures a steady income flow for members throughout the year. To date, significant number of members received cows either through direct purchase or a pass-on system, where newborn calves are given to neighbors in need. This initiative has contributed to increased revenue, improved nutrition with protein-rich diets, job creation, enhanced farm productivity through manure use, and climate change mitigation in agriculture. These efforts have also fostered a unified and reconciled coffee community, strengthening social cohesion and economic stability.",
    imgSrc: "/assets/projects/Dairy/Musasa Milk-Stickers for 5L-02.jpg",
    images: [
      "/assets/projects/Dairy/C07A1665.jpg",
      "/assets/projects/Dairy/DSC_2965.jpg",
      "/assets/projects/Dairy/DSC_2967.jpg",
      "/assets/projects/Dairy/DSC_2981.jpg",
      "/assets/projects/Dairy/DSC_2986.jpg",
      "/assets/projects/Dairy/DSC_4827.jpg",
      "/assets/projects/Dairy/IMG_3920.JPG",
      "/assets/projects/Dairy/IMG_3955.JPG",
      "/assets/projects/Dairy/Musasa Milk-Stickers for 5L-02.jpg",
    ],
  },
  {
    id: 3,
    title: "MUSASA Coffee School",
    description:
      "Established in 2021, MUSASA Coffee School aims to inspire and equip the younger generation with advanced coffee farming skills, barista training, cupping, and quality analysis. By aligning these skills with employment opportunities in the hospitality sector, the school prepares students for meaningful careers in the coffee industry. Additionally, the school offers vocational training in handcrafts, providing alternative income opportunities during the off-season and reducing the need for migration to cities in search of jobs. This initiative strengthens the local economy and fosters a dedicated, skilled workforce in the coffee sector. ",
    imgSrc: "/assets/projects/Coffee_School/DSC_4507.jpg",
    images: [
      "/assets/projects/Coffee_School/DSC_4182.jpg",
      "/assets/projects/Coffee_School/DSC_4208.jpg",
      "/assets/projects/Coffee_School/DSC_4252.jpg",
      "/assets/projects/Coffee_School/DSC_4455.jpg",
      "/assets/projects/Coffee_School/DSC_4507.jpg",
      "/assets/projects/Coffee_School/DSC_4530.jpg",
      "/assets/projects/Coffee_School/DSC_4568.jpg",
      "/assets/projects/Coffee_School/DSC_4633.jpg",
    ],
  },
  {
    id: 4,
    title: "Musasa Agrotourism",
    description:
      "Embark on a journey through the complete coffee value chain with a visit to Musasa Coffee. Our immersive tour offers a unique blend of modern and traditional demonstrations at each stage of coffee processing, providing insight into every step from seed to cup. Experience the essence of farm life as you delve into the stories of the farmers, spending quality time in the fields and immersing yourself in the sights, sounds, and smells of nature. Gain a deeper understanding of what goes into your morning cup as you learn firsthand from those who live and breathe coffee farming. The highlight of your day awaits as you savor café services, seated atop a hill peak at an elevation of 2200 meters, witnessing the breathtaking sunrise over the horizon. This unforgettable experience offers a glimpse into the heart and soul of coffee cultivation while indulging your senses amidst the beauty of nature. ",
    imgSrc: "/assets/projects/Agrotourism/XVI_9315.jpg",
    images: [
      "/assets/projects/Agrotourism/_DSC3320.jpg",
      "/assets/projects/Agrotourism/_DSC3400.jpg",
      "/assets/projects/Agrotourism/IMG_1973.JPG",
      "/assets/projects/Agrotourism/IMG_1983.JPG",
      "/assets/projects/Agrotourism/IMG_1986.JPG",
      "/assets/projects/Agrotourism/IMG_1987.JPG",
      "/assets/projects/Agrotourism/Musasa_6.JPG",
      "/assets/projects/Agrotourism/Musasa_19.JPG",
      "/assets/projects/Agrotourism/Musasa_23.JPG",
      "/assets/projects/Agrotourism/Musasa_34.JPG",
      "/assets/projects/Agrotourism/Musasa_35.JPG",
      "/assets/projects/Agrotourism/XVI_8865.jpg",
      "/assets/projects/Agrotourism/XVI_9071.jpg",
      "/assets/projects/Agrotourism/XVI_9303.jpg",
      "/assets/projects/Agrotourism/XVI_9315.jpg",
      "/assets/projects/Agrotourism/XVI_9472.jpg",
    ],
  },
];
