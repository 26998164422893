import React from "react";

const teamMembers = [
  {
    name: "MUBERA Celestin ",
    role: "President of the board",
    // description:
    //   "The chairman of Board is qualified medical officer with rich experience in community-based organization and management in decades.",
    image: "/assets/Mubera_Celestin.jpg",
  },
  {
    name: "NIYONAMBAZA Etienne",
    role: "Vice President of the board",
    // description:
    //   "Ernest, with extensive coffee sector experience, manages Dukunde Kawa operations, driving strategic improvements for communities.",
    image: "/assets/Niyonambaza_Etienne.jpg",
  },
  {
    name: "MUSENGIMANA Anathalie",
    role: "Secretary of the board",
    // description:
    //   "Ernest, with extensive coffee sector experience, manages Dukunde Kawa operations, driving strategic improvements for communities.",
    image: "/assets/Musengimana_Anathalie.jpg",
  },
  {
    name: "MUSABIMANA Marie Chantal",
    role: "Board member",
    // description:
    //   "Master's candidate in accounting with 10 years' experience, currently manages financial transactions at Dukunde Kawa.",
    image: "/assets/Musabimana_Marie_Chantal.jpg",
  },
  {
    name: "NIYONSENGA Jean Bosco",
    role: "Board member",
    // description:
    //   "She is a professional Certified Quality & Grader officer and has 5 years working with Dukunde Kawa Co-operative.",
    image: "/assets/NIYONSENGA_Jean_Bosco.jpg",
  },
  {
    name: "NSHIMYIMANA Ernest",
    role: "Managing Director of the coop",
    // description:
    //   "He has a degree in Agronomy and working experience of four years with Dukunde Kawa cooperative.",
    image: "/assets/Nshimiyimana_Ernest.jpg",
  },
  {
    name: "MUHIRE Gilbert",
    role: "Marketing officer",
    image: "/assets/MUHIRE_Gilbert.jpg",
  },
  {
    name: "MUKAMURIGO Emerthe",
    role: "Quality Control Officer",
    image: "/assets/MUKAMURIGO_Emerthe.jpg",
  },
  {
    name: "NYIRABANTU Philomene",
    role: "Production Manager",
    image: "/assets/NYIRABANTU_Philomene.jpg",
  },
  {},
  {
    name: "HAKIZIMANA Damien",
    role: "Accountant",
    image: "/assets/chief_accountant.jpg",
  },
  {},
];

const MeetOurTeam = () => {
  return (
    <section className="team bg-yellow-50">
      <h2 className="text-4xl font-bold text-green-800 text-center pb-8">
        Meet Our Team
      </h2>
      <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
        {teamMembers.map((member, index) => (
          <div key={index} className="text-center">
            <div className="team-container lg:h-72 overflow-hidden">
              <img
                src={member.image}
                alt={member.name}
                className="team-image mb-4 object-fill"
              />
            </div>
            <h3 className="text-xl font-bold">{member.name}</h3>
            <p className="text-green-800 font-semibold">{member.role}</p>
            {/* <p className="mt-2 text-sm text-gray-500">{member.description}</p> */}
          </div>
        ))}
      </div>
    </section>
  );
};

export default MeetOurTeam;
