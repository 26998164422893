import React from "react";
import Footer from "../components/Footer";
import RoastedCoffee from "../components/RoastedCoffee";

const RoastedCoffeePage = () => {
  return (
    <div>
      <RoastedCoffee />
      <Footer />
    </div>
  );
};

export default RoastedCoffeePage;
