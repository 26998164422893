import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/Home";
import AboutUsPage from "./pages/AboutUsPage";
import Stations from "./pages/Cws";
import FullyWashedCoffeePage from "./pages/FullyWashedCoffeePage";
import HoneyCoffeePage from "./pages/HoneyCoffeePage";
import RoastedCoffeePage from "./pages/RoastedCoffeePage";
import NaturalCoffeePage from "./pages/NaturalCoffeePage";
import ContactUsPage from "./pages/Contact";
import GalleryPage from "./pages/Gallery";
import ProjectsPage from "./pages/Projects";
import ProjectPage from "./pages/Project";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/our-cws" element={<Stations />} />
        <Route path="/fullywashed-coffee" element={<FullyWashedCoffeePage />} />
        <Route path="/honey-coffee" element={<HoneyCoffeePage />} />
        <Route path="/roasted-coffee" element={<RoastedCoffeePage />} />
        <Route path="/natural-coffee" element={<NaturalCoffeePage />} />
        <Route path="/contact-us" element={<ContactUsPage />} />
        <Route path="/gallery" element={<GalleryPage />} />
        <Route path="/projects" element={<ProjectsPage />} />
        <Route path="/projects/:id" element={<ProjectPage />} />
      </Routes>
    </Router>
  );
};

export default App;
